<template>
    <v-layout row>
        <v-flex xs12 sm6 offset-sm3>
            <v-list>
                <v-list-group no-action v-model="member.active" prepend-icon="check_circle">
                    <template v-slot:activator>
                        <v-list-tile>
                            <v-list-tile-content>
                                <v-list-tile-title>{{ optionTitle('member') }}</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </template>
                    <v-container fluid>
                        <div>
                            <ul>
                                <li>{{ $t('updateInvoice.store-member-system') }}</li>
                                <li>{{ $t('updateInvoice.auto-match') }}</li>
                            </ul>
                        </div>
                    </v-container>
                </v-list-group>
                <v-list-group no-action v-model="mobile.active" prepend-icon="check_circle">
                    <template v-slot:activator>
                        <v-list-tile>
                            <v-list-tile-content>
                                <v-list-tile-title>{{ optionTitle('mobile') }}</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </template>
                    <v-container fluid>
                        <v-form ref="mobileForm" v-model="mobile.valid">
                            <v-text-field
                                outline clearable
                                name="mobileVehicle"
                                :label="$t('updateInvoice.mobileForm.mobile-vehicle')"
                                :hint="$t('updateInvoice.mobileForm.mobile-hint')"
                                persistent-hint
                                v-model="mobileValue"                                
                                counter=8
                                type="text"
                                :placeholder="$t('updateInvoice.mobileForm.mobile-holder')"
                                :rules="mobile.rules"
                                :error="mobile.error"
                                :error-messages="mobile.errorMsg"
                            >
                                <template v-slot:append>
                                    <v-fade-transition leave-absolute>
                                        <v-progress-circular
                                            v-if="mobile.checking"
                                            size="24"
                                            color="info"
                                            indeterminate
                                        ></v-progress-circular>
                                        <v-icon v-else-if="mobile.valid" color="green">check_circle_outline</v-icon>
                                        <v-icon v-else color="warning">error_outline</v-icon>
                                    </v-fade-transition>
                                </template>
                            </v-text-field>
                        </v-form>
                    </v-container>
                </v-list-group>
                <v-list-group no-action v-model="certificate.active" prepend-icon="check_circle">
                    <template v-slot:activator>
                        <v-list-tile>
                            <v-list-tile-content>
                                <v-list-tile-title>{{ optionTitle('certificate') }}</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </template>
                    <v-container fluid>
                        <v-form ref="certificateForm" v-model="certificate.valid">
                            <v-text-field
                                outline clearable
                                name="certificateVehicle"
                                :label="$t('updateInvoice.certificateForm.citizen-vehicle')"
                                :hint="$t('updateInvoice.certificateForm.citizen-hint')"
                                persistent-hint
                                v-model="certificate.value"
                                counter=16
                                type="text"
                                mask="AA##############"
                                :placeholder="$t('updateInvoice.certificateForm.citizen-holder')"
                                :rules="certificate.rules"
                            ></v-text-field>
                        </v-form>
                    </v-container>
                </v-list-group>
                <v-list-group no-action v-model="easycard.active" prepend-icon="check_circle">
                    <template v-slot:activator>
                        <v-list-tile>
                            <v-list-tile-content>
                                <v-list-tile-title>{{ optionTitle('easycard') }}</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </template>
                    <v-container fluid>
                        <div>
                            <ul>
                                <li>{{ $t('updateInvoice.easycard-card-number') }} {{cardDetail(1)}}</li>
                                <li>{{ $t('updateInvoice.store-card-system') }}</li>
                                <li>{{ $t('updateInvoice.vehicle-merge') }}</li>
                            </ul>
                        </div>
                    </v-container>
                </v-list-group>
                <v-list-group no-action v-model="ipass.active" prepend-icon="check_circle">
                    <template v-slot:activator>
                        <v-list-tile>
                            <v-list-tile-content>
                                <v-list-tile-title>{{ optionTitle('ipass') }}</v-list-tile-title>
                            </v-list-tile-content>
                        </v-list-tile>
                    </template>
                    <v-container fluid>
                        <div>
                            <ul>
                                <li>{{ $t('updateInvoice.ipass-card-number') }} {{cardDetail(2)}}</li>
                                <li>{{ $t('updateInvoice.store-card-system') }}</li>
                                <li>{{ $t('updateInvoice.vehicle-merge') }}</li>
                            </ul>
                        </div>
                    </v-container>
                </v-list-group>
            </v-list>
            <v-btn block large color="primary" @click="submit">{{ btnCheck() }}</v-btn>
            <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err" :timeout="dialog.timeout"></waiting-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import WaitingDialog from '@/components/WaitingDialog.vue'
import WaitDialogControl from '@/mixins/WaitDialogControl.vue'
import { HostUrl, SideBtnType } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import i18n from '@/i18n'

const MobileVehicleLength = 8
export const VehicleNames = {
    member: i18n.t('updateInvoice.member-vehicle'),
    mobile: i18n.t('updateInvoice.mobile-vehicle'),
    easycard: i18n.t('updateInvoice.easycard-vehicle'),
    ipass: i18n.t('updateInvoice.ipass-vehicle'),
    certificate: i18n.t('updateInvoice.citizen-vehicle')
}

export default {
    name: 'UpdateInvoce',
    components: { WaitingDialog },
    mixins: [ WaitDialogControl ],
    data() {
        return {
            member: { title: i18n.t('updateInvoice.member-vehicle'), active: false },
            mobileValue: '', // 原本想放在 mobile object 中, 但用 deep watch 會被其他欄位數值影響, 導致無限迴圈, 因此獨立出來
            mobile: {
                title: i18n.t('updateInvoice.mobile-vehicle'),
                active: false,
                rules: [
                    v => !!v || i18n.t('updateInvoice.mobileForm.rules.emptyErr'),
                    v => (!v || /^\/[0-9A-Za-z+.-]{7}$/.test(v)) || i18n.t('updateInvoice.mobileForm.rules.validErr')
                ],
                valid: false,
                checking: false, // 標示正在等待驗證手機載具條碼 API 回覆
                error: false,
                errorMsg: ''
            },
            certificate: {
                value: '',
                title: i18n.t('updateInvoice.certificate-vehicle'),
                active: false,
                rules: [
                    v => !!v || i18n.t('updateInvoice.certificateForm.rules.emptyErr'),
                    v => (!v || /^[A-Z]{2}\d{14}$/.test(v)) || i18n.t('updateInvoice.certificateForm.rules.validErr')
                ],
                valid: false
            },
            easycard: { title: i18n.t('updateInvoice.easycard-vehicle'), active: false },
            ipass: { title: i18n.t('updateInvoice.ipass-vehicle'), active: false }
        }
    },
    watch :{
        mobileValue (value) {
            if(value && value.length == MobileVehicleLength) {
                this.checkMobileValue()
            }
        }
    },
    methods: {
        init() {
            this.valid = false
            let invoice = store.getters.user.invoice
            if (invoice) {
                if ('target' in invoice) this[invoice.target].active = true
                this.mobileValue = invoice.mobile || ''
                this.certificate.value = invoice.certificate || ''
                this.easycard.value = invoice.easycard || ''
                this.ipass.value = invoice.ipass || ''
                this.checkMobileValue()
            }
        },
        optionTitle(vehicleKey) {
            if (vehicleKey == 'mobile') return i18n.t('updateInvoice.mobile-vehicle')
            else if (vehicleKey == 'certificate') return i18n.t('updateInvoice.citizen-vehicle')
            else if (vehicleKey == 'easycard') return i18n.t('updateInvoice.easycard-vehicle')
            else if (vehicleKey == 'ipass') return i18n.t('updateInvoice.ipass-vehicle')
            else //if (vehicleKey == 'member')
                return i18n.t('updateInvoice.member-vehicle')
        },
        cardDetail(card){
            if (card == 1 && this.easycard.value != '')
                return this.easycard.value
            else if (card == 2 && this.ipass.value != '')
                return this.ipass.value
            else
                return ""
        },
        getTarget() {
            if (this.member.active) return 'member'
            else if (this.mobile.active) return 'mobile'
            else if (this.certificate.active) return 'certificate'
            else if (this.easycard.active) return 'easycard'
            else if (this.ipass.active) return 'ipass'
            throw new Error('no target...')
        },
        isValid() {
            return (this.mobile.active && this.$refs.mobileForm.validate())
                || (this.certificate.active && this.$refs.certificateForm.validate())
        },
        btnCheck(){
            if (this.isValid()) return i18n.t('updateInvoice.save')
            else return i18n.t('updateInvoice.back')
        },
        checkMobileValue() {
            this.mobile.checking = true
            var self = this
            let url = HostUrl + '/api/invoice/vehicle/check'
            axios.post(url, { barcode: self.mobileValue }, getAxiosConfig())
                .then(function (response) {
                    if (response.data.code === 0) {
                        self.mobile.error = !response.data.valid
                        self.mobile.errorMsg = response.data.valid ? '' : i18n.t('updateInvoice.invalid-barcode')
                    } else {
                        self.mobile.error = true
                        self.mobile.errorMsg = i18n.t('updateInvoice.unable-verify')
                    }
                })
                .catch(function (error) {
                    console.error('check mobile bar code failed', error)
                    self.mobile.error = true
                    self.mobile.errorMsg = i18n.t('updateInvoice.unable-verify')
                })
                .finally(function () {
                    self.mobile.checking = false
                })
        },
        submit() {
            if (this.isValid()) {
                this.showDialog(i18n.t('updateInvoice.update-setting'), i18n.t('updateInvoice.update-fail'))
                var payload = {
                    invoice: {
                        target: this.getTarget(),
                        mobile: this.mobileValue ? this.mobileValue.toUpperCase() : '',
                        certificate: this.certificate.value || ''
                    }
                }
                let url = HostUrl + '/api/user/profile';
                var self = this
                axios.patch(url, payload, getAxiosConfig())
                    .then(function (response) {
                        if (response.data.code === 0) {
                            store.commit('setSnackBar', { message: i18n.t('updateInvoice.update-complete'), color: 'success' })
                            store.commit('setUserProfile', response.data.profile)
                            self.$router.back()
                        } else store.commit('setSnackBar', { message: response.data.message, color: 'error' })
                    })
                    .catch(function (error) {
                        console.error('update invoice failed', error)
                        store.commit('setSnackBar', { message: i18n.t('updateInvoice.fail-setting'), color: 'error' })
                    })
                    .finally(function () {
                        self.closeDialog()
                    })
            }
            else {
                this.$router.back()
            }
        }
    },
    activated() {
        store.commit('setTitle', 'updateInvoice.receipt-setting')
        store.commit('setSideBtnType', SideBtnType.Back)
        this.init()
    }
}
</script>

<style scoped>
</style>
