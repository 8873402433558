<template>
    <v-card flat>
        <v-subheader>{{ $t('electronicMoneyDetail.receipt-detail') }}</v-subheader>
        <v-card-text class="pt-0">
            <ul v-for="(item, index) in easycardDetail" :key=index class="nobull">
                <li>{{ item.title }} : {{ item.content }}</li>
            </ul>
        </v-card-text>
    </v-card>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: 'electronic-money-detail',
    props: {
        boxId: {
            type: String,
            default: ''
        },
        detail: {
            type: Object,
            required: true
        }
    },
    computed: {
        easycardDetail() {
            let result = [
                { title: i18n.t('electronicMoneyDetail.cabinet-name'), content: this.boxId },
                { title: i18n.t('electronicMoneyDetail.device-ID'), content: this.detail.device_no },
                { title: i18n.t('electronicMoneyDetail.transaction-type'), content: (this.detail.type === '811599' || this.detail.type === '0011') ? i18n.t('electronicMoneyDetail.purchase') : i18n.t('electronicMoneyDetail.returns') },
                { title: i18n.t('electronicMoneyDetail.transaction-hour'), content: this.detail.time },
                { title: this.detail.type === '811599' ? i18n.t('electronicMoneyDetail.easy-card-number') : i18n.t('electronicMoneyDetail.ipass-number'), content: this.detail.print_number },
                { title: i18n.t('electronicMoneyDetail.batch-code'), content: this.detail.batch },
                { title: 'RRN', content: this.detail.RRN },
                { title: i18n.t('electronicMoneyDetail.available-before'), content: this.detail.before_trade_money }
            ]
            if (this.detail.add_money > 0) result.push({ title: i18n.t('electronicMoneyDetail.auto-reload'), content: this.detail.add_money })
            result.push({ title: i18n.t('electronicMoneyDetail.transaction-amount'), content: this.detail.trade_money })
            result.push({ title: i18n.t('electronicMoneyDetail.available-after'), content: this.detail.after_trade_money })
            return result
        }
    }
}
</script>

<style scoped>
    .nobull {
        list-style-type: none;
        padding-left: 0px;
    }
</style>