<template>
    <v-layout row>
        <v-flex xs12 sm6 offset-sm3>
            <v-card flat>
                <v-card-text>
                    <template v-for="(item, index) in details">
                        <v-subheader class="subheading" :key="index">{{ item.header }}</v-subheader>
                        <v-list subheader class="pb-3" :key="item.header">
                            <v-list-tile v-for="subitem in item.values" :key="subitem.key" style="height: 25px;">
                                <v-list-tile-content>
                                    <v-list-tile-title>{{ subitem.key }}</v-list-tile-title>
                                </v-list-tile-content>
                                <v-list-tile-action>{{ translate(subitem.value) }}</v-list-tile-action>
                            </v-list-tile>
                        </v-list>
                    </template>
                    <template v-if="payment && payment.trans">
                        <v-subheader class="subheading">{{ $t('paymentDetail.cells') }}</v-subheader>
                        <v-list subheader>
                            <template v-for="(trans, index) in payment.trans">
                                <v-list-tile avatar :key="index">
                                    <v-list-tile-content>
                                        <v-list-tile-title>{{ $t('paymentDetail.cell') }} {{ trans.door_id }}</v-list-tile-title>
                                        <v-list-tile-sub-title>{{ $t('paymentDetail.transaction-id') }} {{ trans.track_no }}</v-list-tile-sub-title>
                                    </v-list-tile-content>
                                    <v-list-tile-action>
                                        <span>{{ trans.amount }}{{ $t('paymentDetail.NTD') }}</span>
                                    </v-list-tile-action>
                                </v-list-tile> 
                            </template>
                        </v-list>
                    </template>
                    <electronic-money-detail
                        v-if="isElectronicMoney"
                        :box-id="payment.box_name"
                        :detail="payment.detail"
                    ></electronic-money-detail>
                </v-card-text>
            </v-card>
            <waiting-dialog :show.sync="dialog.show" :msg="dialog.msg" :err-msg="dialog.err"></waiting-dialog>
            <message-dialog
                :show.sync="msgDialog.show"
                :msg="msgDialog.msg"
                :primary-btn="msgDialog.primaryBtn"
                @primary="back"
            ></message-dialog>
        </v-flex>
    </v-layout>
</template>

<script>
import axios from 'axios'
import store from '@/store'
import WaitingDialog from '@/components/WaitingDialog'
import WaitDialogControl from '@/mixins/WaitDialogControl'
import MessageDialog from '@/components/MessageDialog.vue'
import MessageDialogControl from '@/mixins/MessageDialogControl.vue'
import ElectronicMoneyDetail from '@/components/payments/ElectronicMoneyDetail'
import { SideBtnType } from '@/store'
import { HostUrl } from '@/store'
import { getAxiosConfig } from '@/utils/AuthService'
import { formatDatetime } from '@/utils/utils'
import { DirectPayType } from '@/components/payments/DirectPay'
import { ElectronicMoneyType } from '@/components/payments/ElectronicMoney'
import { LinePayType } from '@/components/payments/LinePay'
import { JKOPayType } from '@/components/payments/JKOPay'
import { VehicleNames } from '@/views/UpdateInvoice'
import i18n from '@/i18n'

const ACTION_NAMES = {
    PUTIN: i18n.t('paymentDetail.putin'),
    TAKEOUT: i18n.t('paymentDetail.takeout'),
    STOREHOST: i18n.t('paymentDetail.shopping'),
    WASH_PUTIN: i18n.t('paymentDetail.wash-putin'),
    WASH_TAKEOUT: i18n.t('paymentDetail.wash-takeout')
}
export default {
    name: 'payment-detail',
    components: { WaitingDialog, ElectronicMoneyDetail, MessageDialog },
    mixins: [ WaitDialogControl, MessageDialogControl ],
    props: {
        id: {
            type: [String, Number],
            required: true
        }
    },
    data() {
        return {
            payment: null
        }
    },
    computed: {
        isDirect() {
            return this.payment !== null && this.payment.type === DirectPayType
        },
        isElectronicMoney() {
            return this.payment !== null && this.payment.type === ElectronicMoneyType
        },
        details() {
            if (!this.payment) return []
            let details =  [
                { header: i18n.t('paymentDetail.payment-content'), values: [
                    { key: i18n.t('paymentDetail.cabinet-number'), value: this.payment.box_id },
                    { key: i18n.t('paymentDetail.cabinet-name'), value: this.payment.box_name },
                    { key: i18n.t('paymentDetail.total-amount'), value: this.payment.amount_sum + i18n.t('paymentDetail.NTD') },
                    { key: i18n.t('paymentDetail.time'), value: formatDatetime(new Date(this.payment.create_timestamp), false) },
                    { key: i18n.t('paymentDetail.service-type'), value: ACTION_NAMES[this.payment.action] }
                ]}
            ]
            details.push(this.payinfo)
            if (this.invoice) details.push(this.invoice)
            return details
        },
        payinfo() {
            if (!this.payment) return {}
            let values = [{ key: i18n.t('paymentDetail.type'), value: this.payment.type_name }]
            switch (this.payment.type) {
                case DirectPayType:
                    console.log('DirectPayType')
                    values.push({ key: i18n.t('paymentDetail.card-type'), value: this.payment.payinfo.subtype })
                    values.push({ key: i18n.t('paymentDetail.card-number'), value: '**** **** **** ' + this.payment.payinfo.lastfour })
                    break
                case LinePayType: // TODO LINE Pay
                console.log('LinePayType')
                    values.push({ key: i18n.t('paymentDetail.card-type'), value: this.payment.payinfo.subtype })
                    values.push({ key: i18n.t('paymentDetail.card-number'), value: '**** **** **** ' + this.payment.payinfo.maskedCardNumber })
                    break
                case JKOPayType: // TODO JKO Pay
                console.log('JKOPay')
                    values.push({ key: i18n.t('paymentDetail.card-type'), value: this.payment.payinfo.subtype })
                    values.push({ key: i18n.t('paymentDetail.card-number'), value: '**** **** **** ' + this.payment.payinfo.maskedCardNumber })
                    break
                case ElectronicMoneyType:
                    values.push({ key: i18n.t('paymentDetail.card-type'), value: this.payment.payinfo.subtype })
                    values.push({ key: i18n.t('paymentDetail.card-number'), value: this.payment.payinfo.uuid })
                    break
                default:
                    break
            }
            return { header: i18n.t('paymentDetail.payment-type'), values: values }
        },
        invoice() {
            if (!this.payment || !this.payment.invoice) return null
            else {
                let invoice = this.payment.invoice
                return {
                    header: i18n.t('paymentDetail.electronic-receipt'),
                    values: [
                        { key: i18n.t('paymentDetail.vehicle-type'), value: VehicleNames[invoice.type] },
                        { key: i18n.t('paymentDetail.receipt-number'), value: invoice.number },
                        { key: i18n.t('paymentDetail.receipt-date'), value: formatDatetime(new Date(invoice.timestamp), false) }
                    ]
                }
            }
        }
    },
    methods: {
        back() {
            this.$router.back()
        },
        getPaymentDetail() {
            this.showDialog(i18n.t('paymentDetail.get-transaction-content'), i18n.t('paymentDetail.fail-get-content'))
            var vm = this
            axios.get(HostUrl + '/api/payment/history/' + vm.id, getAxiosConfig())
                .then(function (response) {
                    if (response.data.code === 0) {
                        vm.payment = response.data.payment
                    } else {
                        vm.showMsgDialog(response.data.message, i18n.t('paymentDetail.back'))
                    }
                    vm.closeDialog()
                })
                .catch(function () {
                    vm.showMsgDialog(i18n.t('paymentDetail.fail-get-content-later'), i18n.t('paymentDetail.back'))
                    vm.closeDialog()
                })
        },
        translate(string) {
            console.log('string', string)
            if(string === '信用卡') return i18n.t('paymentDetail.credit-card')
            else if(string === '電子票證') return i18n.t('paymentDetail.electronic-money')
            else return string
        }
    },
    activated() {
        store.commit('setTitle', i18n.t('paymentDetail.transaction-content'))
        store.commit('setSideBtnType', SideBtnType.Back)
        this.getPaymentDetail()
    },
    deactivated() {
        store.commit('setSideBtnType', SideBtnType.Navi)
    }
}
</script>